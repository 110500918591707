<template>
  <div class="accomplish">
    <div class="mainbody">
      <div class="mainbody-title"><p>申请开店-工商审批中</p></div>
     <Steps :steps="2"/>
      <div class="success-reg">
        <div class="success-text">
          <p class="icon-check"><a-icon type="check-circle" /></p>
          <p class="fs24">恭喜完成注册</p>
          <p class="fs16">已向您银行预留手机号发送委托书等相关协议，请尽快完成签署</p>
          <p><img src="" alt=""></p>
          <!-- <p>您也可以通过手机扫码进行签署</p> -->
        </div>
        <div class="success-btn">
          <router-link to="/"
            ><div class="go-index">
              <p>进入首页</p>
            </div></router-link
          >
          <div class="go-shop">
            <a :href="$route.query.id">
            <p><span style="font-size: 20px"><a-icon type="user" /></span> 店铺主页</p></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Steps  from "./component/steps.vue"
export default {
    components:{
        Steps
    },
  data() {
    return {
      steps:3
    };
  },
};
</script>
<style lang="less" scoped>
.accomplish {
  margin-bottom: 100px;
   max-width: 1440px;
   min-width: 990px; 
    margin: 20px auto;
   @media screen and (max-width: 1440px){
   padding: 0 15px;
   }

  .mainbody {
    height: 572px;
    background-color: #ffffff;
    position: relative;
    .mainbody-title {
      padding: 30px 0 0 50px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 20px;
      font-weight: 700;
    }
    .success-reg {
      padding: 100px 0 0 0;
      text-align: center;
      .success-text {
        .fs24 {
          font-size: 24px;
          padding: 30px 0 0 0;
          color: #666666;
        }
        .fs16{
          padding: 20px 0 0 0;
          color: #999999;
          font-size: 16px;
        }
      }
    }
    .success-btn {
      display: flex;
      margin: 0 auto;
      padding-top: 55px;
      justify-content: center;
      .go-index,
      .go-shop {
        margin-left: 20px;
        width: 200px;
        font-size: 16px;
        height: 45px;
        line-height: 45px;
      }
      .go-index {
        color: #ffffff;
        background-color: rgba(26, 188, 156, 1);
        :hover {
          background-color: #0fce96;
          opacity: 0.8;
        }
      }
      .go-shop {
        color: #0fce96;
        outline: 1px solid #0fce96;
        :hover {
          background-color: #0fce96;
          color: #ffffff;
        }
      }
    }
  }
}
.icon-check{
    font-size: 100px;
    color: #0fce96;
}

</style>