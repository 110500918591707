<template>
     <div class="schedule">
        <a-steps direction="vertical" size="small" :current="steps">
          <a-step title="1.个人信息" description="" />
          <a-step :title="hasNom ? '2.注册个人卖家' : '2.工商申请表'" description="" />
          <a-step title="3.等待办理" description="" />
        </a-steps>
      </div>
</template>

<script>
export default {
   props:['steps', 'hasNom']
}
</script>
<style lang="less" scoped>
    .schedule {
      max-width: 150px;
      position: absolute;
      top: 120px;
      right: 20px;              
      .ant-steps-vertical {
        /deep/.ant-steps-item-content {
          height: 100px;
        }
      }
    }
</style>